<template>
    <section class="container">
        <stockTopMenu curPosition="调价通知" windowId="priceAdjustmentNotice" module="priceAdjustment"></stockTopMenu>
        <section class="section-main">
            <div :class="['table-content', btnLeft ? 'btnLeft' : 'btnAll']">
                <div class="table-top">
					<basic-button type="primary" icon-class="print" :loading="loading" @click="printList">打印单据</basic-button>
                </div>
                <el-table
                    height="86vh"
					ref="goodsTable"
                    v-loading="loading"
                    :data="tableData"
                    :row-class-name="tableRowClassName"
                    :highlight-current-row="false"
                >
                    <el-table-column prop="date" label="图片" align="center">
                        <template v-slot="{ row }">
                            <el-image
                                v-if="row.imageJson"
                                style="width: 50px; height: 50px"
                                :src="JSON.parse(row.imageJson)[0]"
                                :preview-src-list="JSON.parse(row.imageJson)"
                            >
                            </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="goodsNo" label="货号" align="center"></el-table-column>
                    <el-table-column prop="categoryName" label="二级分类" align="center"> </el-table-column>
                    <el-table-column prop="currentRetailPrice" label="当前零售价" align="center" sortable>
                        <template v-slot="{ row }">
                            <span>￥{{ row.currentRetailPrice }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="retailPrice" label="零售价" align="center" sortable>
                        <template v-slot="{ row }">
                            <span>￥{{ row.retailPrice }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="adjustPrice" label="调价" align="center" sortable>
                        <template v-slot="{ row }">
                            <span>{{ row.adjustPrice }}</span>
                        </template></el-table-column
                    >
                    <el-table-column prop="effectiveDate" label="生效日期" align="center"> </el-table-column>
                    <el-table-column label="库存" align="center">
                        <el-table-column prop="actualQuantity" label="预计库存" align="center"> </el-table-column>
                        <el-table-column prop="usableQuantity" label="可用库存" align="center"> </el-table-column>
                        <el-table-column prop="inTransitQuantity" label="在途库存" align="center"> </el-table-column>
                        <el-table-column prop="notSentQuantity" label="已配未发" align="center"> </el-table-column>
                    </el-table-column>
                </el-table>
                <div class="table-footer">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[100, 200, 500, 1000]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        background
                        :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
            <SearchFormItem :options="options" @searchHandel="sureSharch" @getState="getFormState" department="Account">
            </SearchFormItem>
        </section>
		<DoReceipts v-if="DoReceiptsModal" :webViewUrl="webViewUrl" title="打印调价通知单" @close="DoReceiptsModal = false"></DoReceipts>
    </section>
</template>

<script>
import SearchFormItem from '@/components/SearchFormItem/SearchFormItem'
import stockTopMenu from '@/components/stockTopMenu.vue'
import DoReceipts from '@/views/salesRecord/components/DoReceipts.vue'
import {
    erp2AdjustNoticeQueryMarketNoticePage,
    erp2AdjustNoticeQuerySecondCategoryList
} from '@/libs/http/modules/erp.js'
import request from "@/libs/http";
import {ipc} from "@/utils/ipcRenderer";
import {ipcApiRoute} from "@/api/main";
export default {
    name: 'priceAdjustmentNotice',
    components: {
        SearchFormItem,
        stockTopMenu,
		DoReceipts
    },
    data() {
        return {
            loading: false,
            currentPage: 1,
            pageSize: 100,
            total: 0,
            tableData: [],
            gridOption: {
                gridManagerName: 'priceAdjustmentNotice',
                firstLoading: true,
                supportAutoOrder: false,
                supportCheckbox: true,
                supportExport: false,
                supportMenu: true,
                lineHeight: '30px',
                useTrFocus: true,
                pageSize: 100,
                sizeData: [100, 200, 500, 1000],
                sortMode: 'single',
                isCombSorting: false,
                columnData: [
                    {
                        key: 'imageJson',
                        text: '图片',
                        align: 'center',
                        template: (data) =>
                            `<el-image
                                v-if="row.imageJson" 
                                style="width: 50px; height: 50px"
                                :src="JSON.parse(row.imageJson)[0]"
                                :preview-src-list="JSON.parse(row.imageJson)"
                            >
                            </el-image>`
                    },
                    { key: 'goodsNo', align: 'center', text: '货号' },
                    { key: 'categoryName', align: 'center', text: '二级分类' },
                    {
                        key: 'currentRetailPrice',
                        align: 'center',
                        text: '当前零售价',
                        template: (data) => `<span>￥{{row.currentRetailPrice}}</span>`
                    },
                    {
                        key: 'retailPrice',
                        align: 'center',
                        text: '零售价',
                        template: (data) => `<span>￥{{row.retailPrice}}</span>`
                    },
                    {
                        key: 'adjustPrice',
                        align: 'center',
                        text: '调价',
                        template: (data) => `<span style="color:red">￥{{row.adjustPrice}}</span>`
                    },
                    { key: 'effectiveDate', align: 'center', text: '生效日期' },
                    {
                        key: 'updateTime',
                        text: '库存',
                        align: 'center',
                        width: '600px',
                        children: [
                            {
                                key: 'actualQuantity',
                                align: 'center',
                                text: '预计库存'
                            },
                            {
                                key: 'usableQuantity',
                                align: 'center',
                                text: '可用库存'
                            },
                            {
                                key: 'inTransitQuantity',
                                align: 'center',
                                text: '在途库存'
                            },
                            {
                                key: 'notSentQuantity',
                                align: 'center',
                                text: '已配未发'
                            }
                        ]
                    }
                ],
                ajaxData: this.fetch,
                supportAjaxPage: true,
                height: '94vh',
                rowRenderHandler: (row, index) => {
                    row.gm_row_class_name = row.isMarked ? 'is-marked ' : ''
                    return row
                }
            },
            options: {
                searchItem: [
                    {
                        id: 'goodsNo',
                        type: 'goodsNoSelector',
                        placeholder: '请选择货号',
                        label: '货号',
                        modelKey: 'goodsNo',
                        multiple: true, //单选
                        // value: '', //单选
                        value: [], //多选
                        resField: 'goodsNos'
                    },
                    {
                        id: '1',
                        type: 'select',
                        size: 'small',
                        clearable: true,
                        placeholder: '请选择二级分类',
                        label: '二级分类',
                        multiple: true,
                        collapseTags: true,
                        value: '',
                        resField: 'categoryIds',
                        list: []
                    },
                    {
                        id: '2',
                        type: 'input',
                        size: 'small',
                        clearable: true,
                        placeholder: '请输入零售价',
                        label: '零售价',
                        value: '',
                        resField: 'salaryPrice'
                    },
                    {
                        id: '3',
                        type: 'input',
                        size: 'small',
                        clearable: true,
                        placeholder: '请输入调整价',
                        label: '调价',
                        value: '',
                        resField: 'adjustPrice'
                    }
                ],
                sureBtnVisable: true,
                sureBtnType: 'primary',
                sureBtnSize: 'medium',
                sureBtnText: '查询',
                restBtnVisable: true,
                restBtnType: '',
                restBtnSize: 'medium',
                restBtnText: '重置',
                isExport: false
            },
            queryKey: {},
            btnLeft: null,
            draftState: null,
			webViewUrl: '',
			DoReceiptsModal: false
        }
    },
    created() {
        this.fetch()
        this.getOptions()
    },

    methods: {
        handleSizeChange(val) {
            this.pageSize = val
            this.fetch()
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.fetch()
        },
        fetch() {
            this.loading = true
            erp2AdjustNoticeQueryMarketNoticePage({
                page: this.currentPage,
                pageSize: this.pageSize,
                ...this.queryKey
            })
                .then((data) => {
                    if (data) {
                        this.tableData = data.items.map((i) => ({ ...i, isMarked: false })) || []
                        this.total = Number(data.totalItem) || 1
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            // return new Promise(async (resolve, reject) => {
            //     let reqData = {
            //         page: params.pageData.cPage,
            //         pageSize: params.pageData.pSize,
            //         ...this.queryKey
            //     }
            //     let data = await erp2AdjustNoticeQueryMarketNoticePage(reqData)
            //     const tableData = {
            //         data: data['items'].map((i) => ({ ...i, isMarked: false })) || [],
            //         totals: Number(data.totalItem) || 1
            //     }
            //     resolve(tableData)
            // })
        },
        getOptions() {
            erp2AdjustNoticeQuerySecondCategoryList().then((data) => {
                this.options.searchItem.forEach((item) => {
                    if (item.resField === 'categoryIds') {
                        this.$set(
                            item,
                            'list',
                            data.map((i) => ({ name: i.categoryName, value: i.categoryId }))
                        )
                    }
                })
            })
        },
        sureSharch(e) {
            this.currentPage = 1
            this.queryKey = e
            this.fetch()
            // GridManager.refreshGrid(this.gridOption.gridManagerName, true)
        },
        getFormState(e) {
            this.btnLeft = e
            // GridManager.renderGrid(this.gridOption.gridManagerName) // 使用当前数据重新渲染表头及内容
        },
        tableRowClassName({ row, rowIndex }) {
            if (row.isMarked) {
                return 'marked-row'
            }
            return ''
        },
		printList(){
			if(this.tableData && this.tableData[0]){
				this.$confirm('确认打印调价单', '提示').then(res=>{
					this.DoReceiptsModal = true
					let printer = this.$cache.local.get('printerModel')
					let printCode = this.$cache.local.get('tjtzReceiptCode')
					let printCount = this.$cache.local.get('tjtzReceiptCount')
					let params = {
						sn: this.tableData[0].recodeId,
						marketId: this.$cache.local.get('marketingUnitId')
					}
					this.webViewUrl = `${request.printPreviewRoot}?printerName=${printer}&templateCode=${printCode}&printCount=0&data=${encodeURI(JSON.stringify(params))}`
					let eggRootUrl = `${request.printEggPreviewRoot}?printerName=${printer}&templateCode=${printCode}&`
					const eggSrc = eggRootUrl + `printCount=${printCount}&data=${encodeURI(JSON.stringify(params))}&sn=${params['sn']}`
					ipc.invoke(ipcApiRoute.print, {contentUrl: eggSrc, sn: params['sn']}).then(r => {})
				}).catch(()=>{})
			}
		}
    }
}
</script>

<style lang="scss" scoped>
.description {
    display: inline-block;
    width: 100%;
    line-height: 20px;
}
.section-main {
    width: 100%;
    height: 94vh;
    display: flex;
    .table-content {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .btnLeft {
        width: calc(100% - 330px);
    }
    .btnAll {
        width: 100%;
    }
    .table-top {
        height: 4vh;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: #ffffff;
    }
    .table-footer {
        height: 4vh;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: #ffffff;
    }
}
</style>
<style lang="scss">
.table-content {
    .el-table__row.marked-row {
        background-color: #e2f9d4 !important;
    }
    .el-table tbody tr:hover > td {
        background-color: transparent !important; //修改成自己想要的颜色即可
    }
}
.sub-box {
    color: black;
    padding: 10px 50px;
    background: #c6e7ff;
    display: flex;
    justify-content: center;
    align-content: center;
    cursor: pointer;
    img {
        width: 20px;
        margin-right: 10px;
    }
}
</style>

<template>
    <dialogModule
        width="400px"
        :title="title"
        v-if="salesVisiable"
        top="15"
        :dialogVisible="salesVisiable"
        @close="close"
    >
        <span slot="close" class="el-icon-close" @click="close"></span>
        <div slot="content" class="print-view">
            <div
                :style="{
                    height: webviewHeight
                }"
            >
                <webview class="webview" :src="webViewSrc"></webview>
            </div>
            <div class="description">
                <span>打印机正在打印</span>
                <div>打印完成后，点击下方完成打印按钮</div>
            </div>
            <div class="finish-btn" @click="close">完成打印<span>（按回车可关闭）</span></div>
        </div>
    </dialogModule>
</template>

<script>
import Mousetrap from 'mousetrap'
import 'mousetrap/plugins/global-bind/mousetrap-global-bind'
import dialogModule from '@/components/dialogModule.vue'
export default {
    components: {
        dialogModule
    },
    props: {
        webViewUrl: {
            type: String,
            default: () => ''
        },
        title: {
            type: String,
            default: () => '打印小票'
        },
        webviewHeight: {
            type: String,
            default: () => '600px'
        }
    },
    data() {
        return {
            salesVisiable: false,
            webViewSrc: null
        }
    },
    created() {
        this.salesVisiable = true
        this.webViewSrc = this.webViewUrl
        Mousetrap.bind(['enter', 'enter'], () => {
            this.close()
        })
    },
    methods: {
        close() {
            this.salesVisiable = false
            this.$emit('close', 'refresh')
        }
    }
}
</script>

<style lang="scss" scoped>
.el-icon-close {
    color: #999;
    padding: 10px;
    margin-right: -10px;
    cursor: pointer;
}
.print-view {
    padding-bottom: 5px;
    .webview {
        height: 100%;
        width: 100%;
    }
    .description {
        color: #067cf2;
        font-size: 16px;
        text-align: center;
        div {
            color: #666;
            margin-top: 5px;
        }
    }
    .finish-btn {
        width: 250px;
        height: 50px;
        background: #067cf2;
        border-radius: 4px;
        font-size: 20px;
        color: #ffffff;
        text-align: center;
        line-height: 50px;
        margin: 10px auto;
        cursor: pointer;
        span {
            font-size: 14px;
        }
    }
}
</style>
